<template>
    <div class="nr">
        <div class="bananer_box">
            <div class="bananer_nav">
                <div class="bananer_flex" ref="box">
                    <div class="bananer_title">
                        <span class="underline">智迩</span>
                        核心价值观
                    </div>
                    <div class="content_nr">
                        企业的发展壮大，依托于先进的生产力和生产关系。智迩能够在竞争激烈的IT行业中生存的根本竞争力，是优秀人才构成的团队所具备的强大生产力，以及团队在生产过程中形成和不断优化后的符合客户利益、团队利益、公司每个员工个人利益的先进生产关系。“以人为本”是智迩生存、发展、壮大的源动力。
                        <br />
                        智迩在创立初期，面临资金、人才、品牌等多方面的困难。与智迩一同前行的奋斗者们，要时刻清醒地意识到，智迩会因为这些困难随时面临在激烈的IT行业中被淘汰的大概率可能性。克服这些困难，完成漫漫两万五千里长征，对全世界的初创企业来说，都是一个难题。企业的创立、发展、壮大是一个小概率的幸存者偏差的实践，是智迩的每一个伙伴需要清醒认识到的观点。也正因为大部分企业都会死掉，因此我们要时刻提醒自己如履薄冰、如临深渊，保持一颗对商业市场、竞争环境的敬畏之心。
                        <br />
                        为了让智迩在IT行业中时刻保持竞争力，让智迩在危机中能够生存下来，让与智迩一同奋斗前行的伙伴们取得丰硕的果实，我想以下的原则应该成为所有智迩的小伙伴们具备的核心价值标准和思维模式。
                    </div>
                </div>
            </div>
        </div>
        <div class="contents" :style="[{ 'margin-top': height / 2 - 50 + 'px' }]">
            <div class="culture_flex">
                <img src="../../../assets/aboutUs/culture_img1.png" class="flex_item_left" />
                <div class="flex_item_right">
                    <div class="nr_title">一、 坚持以客户为中心的核心准则不动摇</div>
                    <div class="content_nr">
                        智迩身处竞争激烈的to B IT行业。这个行业生态中，有无数关系过硬、技术能力强的竞争对手。
                        智迩只有将自己所有的资源和力量集中在以客户为中心、做好客户服务，
                        同时不断提高自身的技术实力、产品实力，不断超越客户期望值的阈值， 才能真正在企业服务市场基业长青。
                        <br />
                        以客户为中心，要求智迩的每一名伙伴，具备客户至上的理念。在这个过程中，
                        能为客户利益牺牲个人利益的伙伴，是智迩在前行过程中的领路人与灯塔。
                        智迩的伙伴们，也要时刻保持谦逊、空杯的心态，始终做好客户服务这件日复一日、年复一年的重复性工作，
                        勿以善小而不为。我们坚信，人性向善，只要全心全意为客户服务，一定能获得客户的认可和企业经营上的回报，
                        从而让智迩在竞争激烈的企业服务市场脱颖而出。
                        <br />
                        智迩的业务模式、组织架构在多变的IT行业中，要牢牢符合以客户为中心的基本原则。
                        强一线、小机关，薪酬激励向一线战壕倾斜，给一线奋斗的同仁提供充足的弹药炮火， 这些在智迩的成长之路中需要时刻谨记和践行。
                    </div>
                </div>
            </div>

            <div class="personnel_flex" style="margin-top: 90px">
                <div class="personnel_nr">
                    <div class="nr_title">二、 始终树立艰苦奋斗、求知上进的人才榜样</div>
                    <div class="content_nr">
                        IT行业始终是以人的聪明才智为基础构建的知识密集型行业。保持好奇心、保持学习的动力、保持奋斗上进的欲望，
                        是IT行业所有从业者必备的基础素质。
                        <br />
                        智迩对于伙伴的选择，一定是基于好奇心、学习动力、奋斗欲望等基础能力，具备这些基础能力团结在一起的小伙伴们，
                        一定会具备IT行业顶尖的创造力和战斗力，在瞬息万变、新老更替频繁的科技行业中，保持竞争力。
                        <br />
                        对于那些有好奇心、学习动力、奋斗欲望的同行者，智迩一定并且必须给予足够的创造力施展空间，
                        无论是在职级、工作范畴、薪资、领导力等方面给予足够的支撑，让这样的优秀人才能够成为所有人的榜样，促进大家一同进步。
                    </div>
                </div>
            </div>

            <div class="ren_flex" style="margin-top: 140px">
                <div class="ren_nr">
                    <div class="nr_title">三、 打造和谐与碰撞并存的企业文化</div>
                    <div class="content_nr">
                        儒家文化讲究中庸之道，但中庸的环境未必能培养出将军。在中国市场的中庸文化下，
                        智迩的团队不仅要适应这种中庸文化，对外对内能做到与客户、团队和谐相处，
                        同时也要具备敢于挑战、敢于突破思维禁锢、敢于异想天开甚至“白日做梦”的勇气和欲望。
                        智迩要鼓励突破常规、突破中庸的思维方式和行为方式，如果这些思维方式、行为方式能够对更好的服务客户带来正面的效用，
                        应该将这些不符合世俗的不循规蹈矩的做法在内部表扬、奖励、发扬光大
                        <br />
                        智迩秉承的原则是“黑猫白猫，抓到老鼠就是好猫”。如果在中庸文化与天马行空的激进变革发生剧烈冲突时，
                        首先应当考虑的因素是哪种方式更符合“以客户为中心”、“更有效服务客户”的原则，
                        我们应当鼓励所有小伙伴在基于为客户更多考虑的基础上提出的科学可行的建议，
                        并且将这些建议积极放入组织变革、绩效变革、薪酬变革等企业管理行为活动中。
                    </div>
                </div>
            </div>

            <div class="xin_flex" style="margin-top: 200px">
                <div class="xin_nr">
                    <div class="nr_title">四、 鼓励并倡导“我为人人、人人为我”的人际关系准绳</div>
                    <div class="content_nr">
                        智迩从绩效、薪酬、员工评价等各方面，应当对那些能够先行表率做到“我为人人”的优秀员工进行先行激励和榜样的树立。
                        <br />
                        企业服务行业要求每个从业者都具备“我为人人”的服务意识，只有先伸出手做到“我为人人”，才有可能实现客户、合作伙伴、团队其他成员“人人为我”的回报与馈赠。
                        <br />
                        我们相信“人性本善”、“付出就有回报”。我们也接受别人不领情、不为你的付出感觉到应当回报，并把这些人性自私的一面当作存在即合理。但我们不会因为人性自私的一面改变我们的企业文化、行事方式，始终向善，“毋以善小而不为”。
                        <br />
                        公司坚持“不让雷锋吃亏”、“前行的灯塔给予更多的燃料点亮”的原则，对那些能身先士卒作出“我为人人”表率的伙伴，一定给予更多的短中长期激励。
                    </div>
                </div>
            </div>

            <div class="di_flex" style="margin-top: 170px">
                <div class="di_nr">
                    <div class="content_nr">
                        智迩的价值观是智迩在企业服务市场前进的原则，也是智迩能够发展壮大、凝聚无数优秀人才的信条。
                        与各位和智迩一同奋斗的小伙伴们共勉，希望我们能在瞬息万变、竞争激烈的IT企业服务行业，依靠我们的信仰和精神力量，
                        收获公司的成长、团队的成长，得到个人物质、精神的双丰收。给一线奋斗的同仁提供充足的弹药炮火，
                        这些在智迩的成长之路中需要时刻谨记和践行。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/common.css'
export default {
    name: 'Culture',
    data() {
        return {
            height: 0
        }
    },
    methods: {},
    mounted() {
        this.height = this.$refs.box.scrollHeight
    }
}
</script>

<style scoped>
.di_nr {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    position: absolute;
    width: 60%;
    bottom: 0;
    right: 0;
    padding: 30px;
}
.di_flex {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url(../../../assets/aboutUs/culture_img5.png);
    background-repeat: no-repeat;
    background-size: 702px 300px;
    background-position: left;
}
.xin_nr {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    position: absolute;
    width: 74%;
    top: 100px;
    padding: 30px;
}
.xin_flex {
    position: relative;
    width: 100%;
    height: 361px;
    background-image: url(../../../assets/aboutUs/culture_img4.png);
    background-repeat: no-repeat;
    background-size: 528px 361px;
    background-position: right;
}
.ren_nr {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    position: absolute;
    width: 74%;
    top: 80px;
    right: 0;
    padding: 30px;
}
.ren_flex {
    position: relative;
    width: 100%;
    height: 323px;
    background-image: url(../../../assets/aboutUs/culture_img3.png);
    background-repeat: no-repeat;
    background-size: 518px 323px;
    background-position: left;
}
.personnel_nr {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    position: absolute;
    width: 76%;
    top: 50px;
    padding: 30px;
}
.personnel_flex {
    position: relative;
    width: 100%;
    height: 313px;
    background-image: url(../../../assets/aboutUs/culture_img2.png);
    background-repeat: no-repeat;
    background-size: 470px 313px;
    background-position: right;
}
.flex_item_right {
    flex: 1;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    margin-left: 30px;
    padding: 30px;
}
.flex_item_left {
    width: 400px;
}
.nr_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}
.culture_flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
}
/*bananer模块*/
.titles {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    padding: 50px;
}
.bananer_title .underline {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.bananer_title {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
}
.bananer_flex {
    position: absolute;
    top: 17rem;
    left: 10%;
    width: 80%;
    height: auto;
    padding: 40px;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
}
.bananer_box {
    width: 100%;
    height: 32rem;
    background-image: url(../../../assets/aboutUs/culture_bananer.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.title {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    padding: 60px 30px 0 30px;
}
.contents {
    width: 80%;
    margin: 0 auto;
}
</style>
